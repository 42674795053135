import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { AccountContext } from '../../contexts/AccountContext';
import { axiosInstance } from '../../../axiosApi';

const Header = ({}): JSX.Element => {
  const {authenticated, setAuthentication} = useContext(AuthContext);
  const { account, setAccount } = useContext(AccountContext);
    const navigate = useNavigate();

  const handleSignOut = async () => {
    try{
      const response = await axiosInstance.post('blacklist/',{
        'refresh_token': localStorage.getItem("refresh_token")
      })
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      axiosInstance.defaults.headers['Authorization'] = null;
      setAuthentication(false)
      setAccount(undefined);
      navigate('/signin/')
    }finally{
      
    }
  }

  const handleDashboardClick = async () => {
    navigate('/')
  }

  const handleAccountClick = async () => {
    navigate('/account/')
  }

  const handleFeedbackClick = async () => {
    navigate('/feedback/')
  }
  return (
    <AppBar position="static">
        <Toolbar className='bg-gradient-dark shadow-dark'>
          <Typography variant="h6" style={{ flexGrow: 1 }} className='text-white font-weight-bold'>
            Chat AI Ml Operations
          </Typography>
          
          <Button color="inherit" onClick={handleDashboardClick}>Dashboard</Button>
          <Button color="inherit" onClick={handleAccountClick}>Account</Button>
          <Button color="inherit" onClick={handleFeedbackClick}>Feedback</Button>
          <Button color="inherit" onClick={handleSignOut}>Sign Out</Button>
        </Toolbar>
      </AppBar>

  );
};

export default Header;