import { Form, Formik } from 'formik';
import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from 'react';
import { Alert, Box, Button, TextField } from '@mui/material';
import { object, ref, string } from 'yup';
import { axiosInstance } from '../../../axiosApi';
import CustomTextField, { CustomTextFieldProps } from '../../components/CustomTextField/CustomTextField';
import CustomPasswordField from '../../components/CustomPasswordField/CustomPasswordField';
import CustomToastMessage from '../../components/CustomToastMessage/CustomeToastMessage';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../contexts/AccountContext';
import { MOCK_ACCOUNTS } from '../../mockData';
import { AxiosResponse } from 'axios';
import { Account, AccountType } from '../../data';
import { Label } from '@mui/icons-material';
import background from '../../../bg.jpeg'



// export type SignInValues = {
//     email: string;
//     password: string;
// };

// const validationSchema = object().shape({

// })

// interface GetUserResponse {
//     email: string
// }

const TermsOfService = ({}): JSX.Element => {
    const {authenticated, setAuthentication, setNeedsNewPassword} = useContext(AuthContext);
    const { account, setAccount} = useContext(AccountContext);
    const navigate = useNavigate();    
    
    const handleAcknowledge = async (): Promise<void> => {
        
        try{
            const response = await axiosInstance.post('/user/acknowledge_tos/')
            navigate('/')            

        }catch(error){ 
            console.log(error)
        }
    }

    


  return (
    
    <div className='main-content' style={{'height': '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundImage: `url(${background})`,backgroundSize: "cover",
        backgroundRepeat: "no-repeat",}}>
        <div className='container my-auto'>
            <div className='row'>
                <div className='col -lg-4 col-md-8 col-12 mx-auto'>
                    <div className='card z-index-0 fadeIn3 fadeInBottom'>
                        <div className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                            <div className='bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1'>
                                <h4 className='text-white font-weight-bold text-center'>Terms Of Service</h4>
                            </div>
                        </div>
                        <div className='card-body text-center'>
                            <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            onSubmit={handleAcknowledge}
                            >
                                {(formik) => (
                                    <Form>
                                        {/* { {errorMessage &&
                                        <Alert severity="error">{errorMessage}</Alert> }

                                        } */}
                                        
                                        <div className='row'>
                                            <div className='col'>
                                                <Box sx={{
                                                    mb: 2,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    height: 700,
                                                    overflow: "hidden",
                                                    overflowY: "scroll",
                                                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                                                    }}>
<p>Welcome to chat.aimloperations.com! We're excited to have you on board. Before you start using our large language model, supplied by AI ML Operations, LLC, we need you to agree to our Terms of Service.</p>
<h4>1. Acceptance of Terms</h4>
<p>By logging in, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you don't agree, please don't use our services.</p>
<h4>2. Data Confidentiality</h4>
<p>We respect your data. All information entered by you will be contained within your organization and will not be sold, shared, or disclosed to any third parties without your explicit consent.</p>
<h4>3. Use of Services</h4>
<p>You agree to use our large language model for legitimate purposes only. You're responsible for ensuring that your use of our services complies with all applicable laws and regulations.</p>
<h4>4. Intellectual Property</h4>
<p>All intellectual property rights in our services, including the large language model, remain the property of AI ML Operations, LLC. Any information generated by our service is for your use and AI ML Operations, LLC makes no claim to it.</p>
<h4>5. Limitation of Liability</h4>
<p>In no event will we be liable for any damages, including consequential, incidental, or punitive damages, arising from your use of our services.</p>
<h4>6. Changes to Terms</h4>
<p>We may update these Terms of Service from time to time. We'll notify you of significant changes, and your continued use of our services will constitute acceptance of the updated terms.</p>
<h4>7. Governing Law</h4>
<p>These Terms of Service will be governed by and construed in accordance with the laws of the United States and the state of Illinois.</p>
<p>By logging in, you acknowledge that you have read and agree to these Terms of Service. If you have any questions, please don't hesitate to contact us.</p>
Happy using our services!
                                                </Box>

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <Button 
                                                type={'submit'}
                                                // disabled={ formik.isSubmitting}
                                                    //   type={'submit'}
                                                    //   loading={formik.isSubmitting}
                                                    //   disabled={
                                                    //     !formik.isValid || !formik.dirty || formik.isSubmitting
                                                    // }
                                                      >
                                                        Acknowledge
                                                      </Button>
                                                      

                                            </div>
                                        </div>

                                    </Form>
                                )}

                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TermsOfService;