import { Form, Formik } from 'formik';
import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import CustomPasswordField from '../../components/CustomPasswordField/CustomPasswordField';
import { Button } from '@mui/material';
import { axiosInstance } from '../../../axiosApi';
import CustomToastMessage from '../../components/CustomToastMessage/CustomeToastMessage';

export type PasswordResetValues = {
  password1: string;
  password2: string;
};


const PasswordReset = ({}): JSX.Element => {
  const handlePasswordReset = ({password1, password2}: PasswordResetValues): void => {
    try{
      // verify 
      if(password1 === password2){
          const response = axiosInstance.post('token/obtain', {
              'password': password1,
          });
          console.log(response)
      }
    }catch(error){
        console.log('catching the error');
        <CustomToastMessage message={error as string} />
        
    }
}
    
  return (
    <div className='main-content' style={{'height': '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
        <div className='container my-auto'>
            <div className='row'>
                <div className='col -lg-4 col-md-8 col-12 mx-auto'>
                    <div className='card z-index-0 fadeIn3 fadeInBottom'>
                        <div className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                            <div className='bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1'>
                                <h4 className='text-white font-weight-bold text-center'>Password Reset</h4>
                            </div>
                        </div>
                        <div className='card-body text-center'>
                            <Formik
                            initialValues={{
                                password1: '',
                                password2: '',
                            }}
                            onSubmit={handlePasswordReset}>
                                {(formik) => (
                                    <Form>
                                        <div className='row'>
                                            <div className='col'>
                                                <CustomPasswordField 
                                                      label='Password'
                                                      name="password1" 
                                                      changeHandler={(e) => formik.setFieldValue('password1', e.target.value)}                                                />

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <CustomPasswordField 
                                                      label='Confirm Password'
                                                      name="password2" 
                                                      changeHandler={(e) => formik.setFieldValue('password2', e.target.value)}                                                />

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <Button
                                                type={'submit'}
                                                disabled={ formik.isSubmitting}
                                                    //   type={'submit'}
                                                    //   loading={formik.isSubmitting}
                                                    //   disabled={
                                                    //     !formik.isValid || !formik.dirty || formik.isSubmitting
                                                    // }
                                                      >
                                                        Reset Password
                                                      </Button>
                                                      

                                            </div>
                                        </div>

                                    </Form>
                                )}

                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  );
};

export default PasswordReset;