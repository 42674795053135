import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { AccountContext } from '../../contexts/AccountContext';
import Card from '../../components/Card/Card';
import { Box, Button, CardContent, CardHeader, IconButton, Modal, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton } from '@mui/material';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { Form, Formik } from 'formik';
import { Account, AccountType, Feedback, FeedbackType } from '../../data';
import { axiosInstance } from '../../../axiosApi';
import { AxiosResponse } from 'axios';
import { Add, BusinessTwoTone, Delete, DeleteForever } from '@mui/icons-material';
import * as Yup from 'yup';

type FeedbackSubmitValues = {
    text: string
    title: string
    //category: string
  }

  type FeedbackSubmitCardProps = {
    feedbacks: Feedback[],
    setFeedbacks: React.Dispatch<React.SetStateAction<Feedback[]>>
}

const validataionSchema = Yup.object().shape({
    text: Yup.string().min(1, "Need to have at least one character").required("This is requried"),
    title: Yup.string().min(1, "Need to have at least one character").required("This is requried"),
}
)

const FeedbackSubmitCard = ({feedbacks, setFeedbacks}: FeedbackSubmitCardProps) => {
    const handleFeedbackSubmit = async ({text, title}: FeedbackSubmitValues): Promise<void> => {
        try{
          await axiosInstance.post('/feedbacks/', {
            text: text,
            title: title,
            category: 'NOT_DEFINED'
          })
          // put message here
          setFeedbacks([...feedbacks, new Feedback({
    
            title: title,
            text: text,
            status: 'SUBMITTED',
            category: 'NOT_DEFINED',
            
          })])
        } catch{
          // put a message here
        }
      }
      return(
        <Card>
          <div className='card-header'>
              <div className='bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1 d-flex'>
                <h4 className='text-white font-weight-bold '>
                  Submit Feedback
                </h4>            
              </div>
            </div>
        
          <CardContent>
            <Formik
            initialValues={{
              text: '',
              title: '',
              category: '',
            }}
            onSubmit={handleFeedbackSubmit}
            validationSchema={validataionSchema}
            isInitialValid={false}>
              {(formik) => 
                <Form>
                  <div className="row">
                    <div className='col'>
                    <CustomTextField label='Title' name='title' changeHandler={(e) => formik.setFieldValue('title',e.target.value)} isMultline={false}/>
    
    
                    </div>
                    
                    {/* <div className='col'>
                    <CustomTextField label='Category' name='category' changeHandler={(e) => formik.setFieldValue('category',e.target.value)} isMultline={false}/>
    
    
                    </div> */}
                    <div className="row">
                    <div className='col'>
                    <CustomTextField label='Text' name='text' changeHandler={(e) => formik.setFieldValue('text',e.target.value)} isMultline={true}/>
                    </div>
    
                    </div>
                    <div className='row'>

                    
                    <div className='col'>
                      <Button
                        type={'submit'}
                        disabled={!formik.isValid}
                        >Submit
    
                      </Button>
                      </div>
                      
                    </div>
                  </div>
                  
                </Form>
              }
            </Formik>
            
            
          </CardContent>
        </Card>
      )
}

type FeedbackLineProps = {
    feedback: Feedback
}

const FeedbackLine = ({feedback}: FeedbackLineProps): JSX.Element => {
    return(
        <TableRow key={feedback.id}>
            <TableCell>{feedback.title}</TableCell>
            <TableCell>{feedback.category}</TableCell>
            <TableCell>{feedback.text}</TableCell>
            <TableCell>{feedback.status}</TableCell>
        </TableRow>
    )
}

type FeedbackTableCardProps = {
    feedbacks: Feedback[],
    setFeedbacks: React.Dispatch<React.SetStateAction<Feedback[]>>
}

const FeedbackTableCard = ({feedbacks, setFeedbacks}: FeedbackTableCardProps) => {
    

    async function getCompanyUsers(){
        try{
          const {data, }: AxiosResponse<FeedbackType[]> = await axiosInstance.get(`/feedbacks/`);
          setFeedbacks(data.map((item) => new Feedback({
    
              id: item.id,
              title: item.title,
              text: item.text,
              status: item.status,
              category: item.category,
              
            })))
    
    
        }catch(error){
          console.log(error)
        }
      }
    
      useEffect(()=>{
        getCompanyUsers();
      }, [])
    
  return(
    <Card>
        <div className='card-header'>
          <div className='bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1 d-flex'>
            <h4 className='text-white font-weight-bold '>
              Feedback
            </h4>            
          </div>
        </div>
        <CardContent>
          <TableContainer component={Paper}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Text</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {feedbacks.map((feedback) => <FeedbackLine key={feedback.id} feedback={feedback}/>)}

              </TableBody>
            </Table>
          </TableContainer>
          
        </CardContent>
      </Card>
  )
}


const FeedbackPage = ({}): JSX.Element => {
    const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  
  
  return (
    <div className='main-content' style={{'height': '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12' style={{
                    position: 'sticky',
                    top: 0,
                }}>
                    <Header />

                </div>
            </div>
            <Box sx={{
            mb: 2,
            display: "flex",
            flexDirection: "column",
            height: 700,
            overflow: "hidden",
            overflowY: "scroll",
            // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
            }}>
                <FeedbackSubmitCard setFeedbacks={setFeedbacks} feedbacks={feedbacks}/>
                <FeedbackTableCard setFeedbacks={setFeedbacks} feedbacks={feedbacks}/>

            </Box>

            
            
        
        
        </div>
    
        <Footer />
    </div>
  );
};

export default FeedbackPage;