import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

const NotFound = ({}): JSX.Element => {
    
  return (
    <div className='main-content' style={{'height': '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
        <p>404</p>
    </div>
  );
};

export default NotFound;