import { Form, Formik } from 'formik';
import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import CustomPasswordField from '../../components/CustomPasswordField/CustomPasswordField';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { axiosInstance } from '../../../axiosApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomToastMessage from '../../components/CustomToastMessage/CustomeToastMessage';
import background from '../../../bg.jpeg'
import * as Yup from 'yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

export type PasswordResetValues = {
  password1: string;
  password2: string;
};

const initialValues = {password1: '', password2: ''}
const  validationSchema = Yup.object().shape({
    password1: Yup.string().min(6, "Passwords have to be at least 6 digits").required(),
    password2: Yup.string().min(6, "Passwords have to be at least 6 digits").required().oneOf([Yup.ref('password1'), null], "Passwords must match"),
})

const contains_number = (item: string): boolean => {
    const numbers = ['1','2','3','4','5','6','7','8','9','0'];
    const hasNumber = numbers.some((character) => item.includes(character));
    if(hasNumber){
        return true;
    }
    return false;
}

const contains_special_character = (item: string): boolean => {
    const specialCharacters = ['!','@','#','$',',%','^','&','*','(',')','-','_','=','+','/','*','\\','|','`','~','<','>','.','?'];
    const hasSpecialChacater = specialCharacters.some((character) => item.includes(character));
    if(hasSpecialChacater){
        return true;
    }
    return false;
}

const SetPassword = ({}): JSX.Element => {
    const navigate = useNavigate();    
    // see if the user is allowed to come here first
    const [queryParameters] = useSearchParams()
    console.log(queryParameters.get("slug"))
    const slug = queryParameters.get("slug");

    
    try{
        // make sure it comes back as 200 for a good request. Else go to the homepage
        axiosInstance.get(`user/set_password/${slug}`)
    }catch{
        navigate('/')
    }

  const handleSetPassword = ({password1, password2}: PasswordResetValues): void => {
    try{
      // verify 
      if(password1 === password2){
          axiosInstance.post(`user/set_password/${slug}/`, {
              'password': password1,
          });
          
          navigate('/')
      }
    }catch(error){
        console.log('catching the error');
        <CustomToastMessage message={error as string} />
        
    }
}
    
  return (
    <div className='main-content' style={{'height': '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundImage: `url(${background})`,backgroundSize: "cover",
        backgroundRepeat: "no-repeat"}}>
        <div className='container my-auto'>
            <div className='row'>
                <div className='col -lg-4 col-md-8 col-12 mx-auto'>
                    <div className='card z-index-0 fadeIn3 fadeInBottom'>
                        <div className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                            <div className='bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1'>
                                <h4 className='text-white font-weight-bold text-center'>Set your password</h4>
                            </div>
                        </div>
                        <div className='card-body text-center'>
                            <Formik
                            initialValues={initialValues}
                            onSubmit={handleSetPassword}
                            isInitialValid={false}
                            validationSchema={validationSchema}>
                                {(formik) => (
                                    <Form>
                                        <div className='row'>
                                            <div className='col'>
                                                <CustomPasswordField 
                                                      label='Password'
                                                      name="password1" 
                                                      changeHandler={(e) => formik.setFieldValue('password1', e.target.value)}                                                />

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <CustomPasswordField 
                                                      label='Confirm Password'
                                                      name="password2" 
                                                      changeHandler={(e) => formik.setFieldValue('password2', e.target.value)}                                                />

                                            </div>
                                        </div>
                                        <div>
                                        <Stack alignItems="center" direction="row" gap={2}>
                                            {formik.values.password1 === formik.values.password2 ? <CheckCircleIcon fontSize='small' color='success'/> : <ErrorIcon fontSize='small' color='warning'/>}
                                            
                                            <Typography variant="body1">Passwords Match</Typography>
                                            </Stack>
                                        </div>
                                        <div>
                                        <Stack alignItems="center" direction="row" gap={2}>
                                        {formik.values.password1.length > 5 ? <CheckCircleIcon fontSize='small' color='success'/> : <ErrorIcon fontSize='small' color='warning'/>}
                                            <Typography variant="body1">At least 6 characters</Typography>
                                            </Stack>
                                        </div>
                                        <div>
                                        <Stack alignItems="center" direction="row" gap={2}>
                                        {contains_special_character(formik.values.password1) ? <CheckCircleIcon fontSize='small' color='success'/> : <ErrorIcon fontSize='small' color='warning'/>}
                                            <Typography variant="body1">At least one special character</Typography>
                                            </Stack>
                                        </div>
                                        <div>
                                        <Stack alignItems="center" direction="row" gap={2}>
                                            
                                        {contains_number(formik.values.password1) ? <CheckCircleIcon fontSize='small' color='success'/> : <ErrorIcon fontSize='small' color='warning'/>}
                                            <Typography variant="body1">At least one number</Typography>
                                            </Stack>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <Button
                                                type={'submit'}
                                                disabled={!formik.isValid || formik.isSubmitting || 
                                                    !contains_special_character(formik.values.password1) 
                                                    || !contains_number(formik.values.password1) 
                                                }
                                                
                                                    //   type={'submit'}
                                                    //   loading={formik.isSubmitting}
                                                    //   disabled={
                                                    //     !formik.isValid || !formik.dirty || formik.isSubmitting
                                                    // }
                                                      >
                                                        Set password
                                                      </Button>
                                                      

                                            </div>
                                        </div>

                                    </Form>
                                )}

                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  );
};

export default SetPassword;