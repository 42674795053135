import { createContext, ReactNode, useState } from "react"
import { useNavigate } from "react-router-dom";

type AuthProviderProps = {
    children?: ReactNode;
}

type IAuthContext = {
    authenticated: boolean;
    setAuthentication: (newState: boolean) => void;
    needsNewPassword: boolean;
    setNeedsNewPassword: (newState: boolean) => void;
}

const initialValues = {
    authenticated: false,
    setAuthentication: () => {},
    needsNewPassword: false,
    setNeedsNewPassword: () => {}
}

const AuthContext = createContext<IAuthContext>(initialValues);


const AuthProvider = ({children}: AuthProviderProps) => {
    const [ authenticated, setAuthentication ] = useState(initialValues.authenticated);
    const [ needsNewPassword, setNeedsNewPassword] = useState(initialValues.needsNewPassword)
    const navigation = useNavigate();

    return (
        <AuthContext.Provider value={{ authenticated, setAuthentication, needsNewPassword, setNeedsNewPassword}}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }