import { useContext, useEffect, useRef, useState } from "react"
import { WebSocketContext } from "../../contexts/WebSocketContext"
import { Stream } from "node:stream"
import { AccountContext } from "../../contexts/AccountContext"

type ConversationDetailCardProps = {
    message: string
    user_created: boolean

}

type ConversationWebSocketCardProps = {
    user_created: boolean
}

export const ConversationWebSocketCard = ({user_created}: ConversationWebSocketCardProps): JSX.Element => {
    const type = user_created ? 'info' : 'warning'
    const text_align = user_created ? 'right' : 'left'
    
    const [subscribe, unsubscribe] = useContext(WebSocketContext)
    const { account, setAccount } = useContext(AccountContext)
    const messageRef = useRef('')
    const [stateMessage, setStateMessage] = useState<string>('')
    
    
    useEffect(() => {
        /* register a consistent channel name for identifing this chat messages */
        const channelName = `ACCOUNT_ID_${account?.email}`

        /* subscribe to channel and register callback */        
        subscribe(channelName, (message: string) => {
            /* when a message is received just add it to the UI */
            messageRef.current += message
            
            setStateMessage(stateMessage.concat(message))
            
        })

        return () => {
            /* unsubscribe from channel during cleanup */
            unsubscribe(channelName)
        }
    }, [account, subscribe, unsubscribe])
    
    console.log(stateMessage)
    
    return (
        <div className={`card bg-gradient-${type}`}>
            <div className='card-body ' style={{textAlign: `${text_align}`}}>
                <p className='text-white'>{stateMessage}</p>
                </div>
        </div>
    )
}

const ConversationDetailCard = ({message, user_created}: ConversationDetailCardProps): JSX.Element => {
    const type = user_created ? 'info' : 'dark'
    const text_align = user_created ? 'right' : 'left'
    return (
        <div className={`card bg-gradient-dark`} style={{margin: '0.25rem'}}>
            <div className='card-body-small ' style={{textAlign: `${text_align}`, marginRight:  '1rem', marginLeft:  '1rem', marginTop: '1rem'}}>
                <p className='text-white display-linebreak' style={{whiteSpace: "pre-line"}}>{message}</p>
                </div>
        </div>
    )
}

export default ConversationDetailCard;