import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import ConversationCard from './components/ConversationCard';
import MessageCard from './components/MessageCard';


const SamplePage: React.FC = () => {
  const conversations = [
    { id: 1, title: 'Conversation 1' },
    { id: 2, title: 'Conversation 2' },
    { id: 3, title: 'Conversation 3' },
  ];

  const messages = [
    'Message 1',
    'Message 2',
    'Message 3',
    'Message 4',
    'Message 5',
    'Message 6',
    'Message 7',
    'Message 8',
    'Message 9',
    'Message 10',
  ];

  return (
    <div className="app-container">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <Button color="inherit">Dashboard</Button>
          <Button color="inherit">Account</Button>
          <Button color="inherit">Sign Out</Button>
        </Toolbar>
      </AppBar>

      <Container fluid>
        <Row>
          <Col xs={3} className="sidebar">
            <Typography variant="h6">Conversations</Typography>
            {conversations.map((conv) => (
              <ConversationCard key={conv.id} title={conv.title} />
            ))}
          </Col>
          <Col xs={9} className="main-content">
            <Box className="message-box">
              {messages.map((msg, index) => (
                <MessageCard key={index} message={msg} />
              ))}
            </Box>
          </Col>
        </Row>
      </Container>

      <footer className="footer">
        <a href="https://aimloperations.com" target="_blank" rel="noopener noreferrer">
          Visit AIMLOperations.com
        </a>
      </footer>
    </div>
  );
};

export default SamplePage; 