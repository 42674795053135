import { Dispatch, SetStateAction } from "react";
import { Conversation, ConversationType } from "../../data";
import { Box, Button, ButtonBase, Card, CardContent, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { Form, Formik } from "formik";
import CustomTextField from "../CustomTextField/CustomTextField";
import { axiosInstance } from "../../../axiosApi";
import { AxiosResponse } from "axios";
import * as Yup from 'yup';

type ConversationCardProps = {
    title: string,
    conversation_id: number | undefined,
    setSelectConversation: Dispatch<SetStateAction<number | undefined>>; // state function to set the selected conversation
    deleteConversation: (conversation_id: number | undefined)=>void;
};

const ConversationCard = ({title, conversation_id, setSelectConversation, deleteConversation}: ConversationCardProps): JSX.Element => {
    return (
        <Card style={{width: "100%", margin:'0px'}}>
            <div className="row">
            <div className='col-10'>
                <ButtonBase onClick={() => {console.log(conversation_id); setSelectConversation(conversation_id)}} style={{width: "100%", margin:'1px'}}>
                    <CardContent style={{width: "100%", margin:'0px'}}>{title}</CardContent>
                </ButtonBase>

            </div>
            <div className='col-1'>
                <IconButton aria-label="settings" onClick={() => deleteConversation(conversation_id)}>
                <Delete />
            </IconButton>
                
            </div>
            </div>
            
            
        </Card>
    );
}

type ConversationLogProps = {
    conversations: Conversation[];
    setSelectConversation: Dispatch<SetStateAction<number | undefined>>; // state function to set the selected conversation
    setConversations: React.Dispatch<React.SetStateAction<Conversation[]>>;
    deleteConversation: (conversation_id: number | undefined)=>void;
};

type ConversationValues = {
    name: string;
};

const validataionSchema = Yup.object().shape({
    name: Yup.string().min(1, "Need to have at least one character").required("This is requried")
}
)

const initialValues = {name: ''}

const ConversationLog = ({conversations, setConversations, setSelectConversation, deleteConversation}: ConversationLogProps): JSX.Element => {
    const handleConversationSubmit = async ({name}: ConversationValues): Promise<void> => {
        try{
            const {data, }: AxiosResponse<ConversationType> = await axiosInstance.post('/conversations', {
                name: name
            })
            setConversations([...conversations, new Conversation({title: data.title, id: data.id})])

        }catch{

        }
            

    }
    
    return (
        
            <div className="card" style= {{height: 'auto'}}>
            {/* <div className='card-header'>
                <div className='bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1'>
                <Typography variant="h6" style={{ flexGrow: 1 }} className='text-white font-weight-bold'>
                Conversations
                </Typography>
                    
                </div>
                </div> */}
                
                    <Box sx={{
                        mb: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 700,
                        overflow: "hidden",
                        overflowY: "scroll",
                        // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                        }}>
                            
                            {
                                conversations ? (
                                    conversations.map((conversation ) =>     
                                        <ConversationCard key={conversation.id} title={conversation.title} conversation_id={conversation.id} setSelectConversation={setSelectConversation} deleteConversation={deleteConversation}/>
                                        // <ButtonBase onClick={() => setSelectConversation(conversation.id)} style={{width: "100%", margin:'1px'}}>
                                            

                                        // </ButtonBase>
                                        
                                        
                                    
                                    )
                                    

                                ) : (
                                    <p>Conversations will show up here</p>
                                )
                            }
                            
                            </Box>        

                        <div className='card-footer'>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(event, {resetForm}) => {
                                    console.log(1)
                                    handleConversationSubmit(event).then(() => {
                                        console.log(2)
                                        console.log(`initialValues: ${initialValues}`)
                                        resetForm({values: initialValues})
                                        
                                        }
                                    )

                                }}

                                
                                validationSchema={validataionSchema}
                                isInitialValid={false}
                                >
                                    {(formik) => 
                                            <Form>
                                        <div className='row' style={{
                                            position: 'sticky',
                                            bottom: 0
                                        }}>
                                            <div className='col-8'>
                                                <CustomTextField 
                                                label='name' 
                                                name='name' 
                                                changeHandler={(e) => formik.setFieldValue('name',e.target.value)} 
                                                isMultline={false}/>
                                            </div>
                                            <div className='col-2'>
                                                <Button
                                                    type={'submit'}
                                                    disabled={!formik.isValid}
                                                >Send

                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                    }

                        </Formik>

                        
                    

                </div>
                
                {/* <CardContent >
                <div className='row'>
            {
                conversations ? (
                    conversations.map((conversation ) =>     
                        <ConversationCard key={conversation.id} title={conversation.title} conversation_id={conversation.id} setSelectConversation={setSelectConversation} deleteConversation={deleteConversation}/>
                        // <ButtonBase onClick={() => setSelectConversation(conversation.id)} style={{width: "100%", margin:'1px'}}>
                            

                        // </ButtonBase>
                        
                        
                    
                    )
                    

                ) : (
                    <p>Conversations will show up here</p>
                )
            }
                    </div>
            
                </CardContent> */}
            </div>
        
    );
}

export default ConversationLog;