/* Classes for the project */
export interface ConversationPromptType {
    id: number,
    message: string, 
    user_created: boolean,
    created_timestamp: Date,
}

export class ConversationPrompt{
    id: number | undefined;
    message: string = '';
    user_created: boolean = false;
    created_timestamp: Date = new Date();
    // TODO: add  a date time stamp

    constructor(initializer?: any){
        if(!initializer) return;
        if (initializer.id) this.id = initializer.id;
        if (initializer.message) this.message = initializer.message;
        if (initializer.user_created) this.user_created = initializer.user_created;
        if (initializer.created_timestamp) this.created_timestamp = initializer.created_timestamp;
    }

}

export interface CompanyType {
    id: number;
    name: string;
    state: string;
    zipcode: string;
    address: string;
}

export class Company {
    id: number | undefined;
    name: string = '';
    state: string = '';
    zipcode: number | undefined;
    address: string = '';

    constructor(initializer?: any){
        if (!initializer) return;
        if (initializer.id) this.id = initializer.id;
        if (initializer.name) this.name = initializer.name;
        if (initializer.state) this.state = initializer.state;
        if (initializer.zipcode) this.zipcode = initializer.zipcode;
        if (initializer.address) this.address = initializer.address;
    }


}

export interface ConversationType {
    id: number;
    title: string;
    conversationDetail: ConversationPrompt[];
}

export class Conversation {
    id: number | undefined;
    title: string ='';
    conversationDetail: ConversationPrompt[] = [];
    account: Account | undefined;

    constructor(initializer?: any){
        if(!initializer) return;
        if (initializer.id) this.id = initializer.id;
        if (initializer.title) this.title = initializer.title;
        if (initializer.conversationDetail) this.conversationDetail = initializer.conversationDetail;
        if (initializer.account) this.account = initializer.account;
    }
}

export interface AnnouncementType {
    status: string;
    message: string;
}

export class Announcement {
    status: string = 'default';
    message: string ='';

    constructor(initializer?: any){
        if (!initializer) return;
        if (initializer.status) this.status = initializer.status;
        if (initializer.message) this.message = initializer.message;
    }
}

export interface FeedbackType {
    id: number;
    title: string;
    status: string;
    text: string;
    category: string;
}

export class Feedback {
    id: number = 0;
    title: string = '';
    status: string = '';
    text: string = '';
    category: string = '';
    constructor(initializer?: any){
        if(!initializer) return;
        if (initializer.id) this.id = initializer.id;
        if (initializer.status) this.status = initializer.status;
        if (initializer.title) this.title = initializer.title;
        if (initializer.text) this.text = initializer.text;
        if (initializer.category) this.category = initializer.category;

    }
}

export interface AccountType {
    email: string;
    first_name: string;
    last_name: string;
    role: string  | undefined;
    company: Company | undefined;
    has_usable_password: boolean;
    is_company_manager: boolean;
    is_active: boolean;
    has_signed_tos: boolean;
}

export class Account {
    email: string = '';
    first_name: string ='';
    last_name: string = '';
    role: string  | undefined;
    company: Company | undefined;
    is_company_manager: boolean = false;
    has_password: boolean = false;
    is_active: boolean = false;
    has_signed_tos: boolean = false;
    constructor(initializer?: any){
        if (!initializer) return;
        if (initializer.email) this.email = initializer.email;
        if (initializer.first_name) this.first_name = initializer.first_name;
        if (initializer.is_company_manager) this.is_company_manager = initializer.is_company_manager;
        if (initializer.has_password) this.has_password = initializer.has_password;
        if (initializer.is_active) this.is_active = initializer.is_active;
        if (initializer.has_signed_tos) this.has_signed_tos = initializer.has_signed_tos;
        if (initializer.last_name) this.last_name = initializer.last_name;
        if (initializer.role) this.role = initializer.role;
        if (initializer.company) this.company = initializer.company;
        
    }
}