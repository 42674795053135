import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { AccountContext } from '../../contexts/AccountContext';
import Card from '../../components/Card/Card';
import { Button, CardContent, CardHeader, IconButton, Modal, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton } from '@mui/material';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { Form, Formik } from 'formik';
import { Account, AccountType } from '../../data';
import { axiosInstance } from '../../../axiosApi';
import { AxiosResponse } from 'axios';
import { Add, BusinessTwoTone, Delete, DeleteForever } from '@mui/icons-material';
import * as Yup from 'yup';


type CompanyAccountLineProps = {
  user: Account
  handleUserUpdate: (email: string, field: string, value: string) => void
}

const CompanyAccountLine = ({user, handleUserUpdate}: CompanyAccountLineProps): JSX.Element => {
  const {account} = useContext(AccountContext);

  return(
    <TableRow key={user.email}>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.first_name}</TableCell>
      <TableCell>{user.last_name}</TableCell>
      <TableCell >
        <Switch checked={user.is_company_manager} onChange={(event) => handleUserUpdate(user.email, 'company_manager', event.target.value)} disabled={account?.email === user.email} />
      </TableCell>
      <TableCell >
        <Switch checked={user.is_active} onChange={(event) => handleUserUpdate(user.email, 'is_active', event.target.value)} />
      </TableCell>
      <TableCell >
        <Switch checked={user.has_password} onChange={(event) => handleUserUpdate(user.email, 'has_password', event.target.value)} disabled={!user.has_password} />
      </TableCell>
      <TableCell>
        <IconButton>
          <DeleteForever color="warning" onClick={() => handleUserUpdate(user.email, 'delete', '')} />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

type InviteValues = {
  email: string
}
const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("This is requried")
}
)

const AddUserCard = ({}): JSX.Element => {
  const handleInvite = async ({email}: InviteValues): Promise<void> => {
    try{
      await axiosInstance.post('/user/invite/', {
        'email': email
      })
    } catch{
      // put a message here
    }
  }
  return(
    <Card>
      <div className='card-header'>
          <div className='bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1 d-flex'>
            <h4 className='text-white font-weight-bold '>
              Add a user
            </h4>            
          </div>
        </div>
    
      <CardContent>
        <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={handleInvite}
        isInitialValid={false}
        validationSchema={validationSchema}>
          {(formik) => 
            <Form>
              <div className="row">
                <div className='col-11'>
                <CustomTextField label='Email' name='email' changeHandler={(e) => formik.setFieldValue('email',e.target.value)} isMultline={false}/>


                </div>
                <div className='col-1'>
                  <Button
                    type={'submit'}
                    disabled={!formik.isValid}>Invite

                  </Button>
                  
                </div>
              </div>
              
            </Form>
          }
        </Formik>
        
        
      </CardContent>
    </Card>
  )
}

const CompanyManagerCard = ({}): JSX.Element => {
  const [users, setUsers] = useState<Account[]>([]);
  const {account}= useContext(AccountContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleUserUpdate = async(email: string, field: string, value: string): Promise<void> => {
    console.log(email, field, value)
    if(field === 'delete'){
    await axiosInstance.delete(`/company_users`, {   
      data: {'email':email}
        
      })
    }else {
      await axiosInstance.post(`/company_users`, {
        'email':email,
        'field': field,
        'value': value
      });
    }

    

    // get all of th edata again
    try{
      const {data, }: AxiosResponse<AccountType[]> = await axiosInstance.get(`/company_users`);
      setUsers(data.map((item) => new Account({

          email: item.email,
          first_name: item.first_name,
          last_name: item.last_name,
          is_company_manager: item.is_company_manager,
          has_password: item.has_usable_password,
          is_active: item.is_active,
          company: undefined
        })))


    }catch(error){
      console.log(error)
    }

  }

  async function getCompanyUsers(){
    try{
      const {data, }: AxiosResponse<AccountType[]> = await axiosInstance.get(`/company_users`);
      setUsers(data.map((item) => new Account({

          email: item.email,
          first_name: item.first_name,
          last_name: item.last_name,
          is_company_manager: item.is_company_manager,
          has_password: item.has_usable_password,
          is_active: item.is_active,
          company: undefined
        })))


    }catch(error){
      console.log(error)
    }
  }

  useEffect(()=>{
    getCompanyUsers();
  }, [])

  return(
    <>
      <Card>
        <div className='card-header'>
          <div className='bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1 d-flex'>
            <h4 className='text-white font-weight-bold '>
              Company Accounts
            </h4>            
          </div>
        </div>
        <CardContent>
          <TableContainer component={Paper}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Is Manager</TableCell>
                  <TableCell>Is Active</TableCell>
                  <TableCell>Has Password</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {users.map((user) => <CompanyAccountLine user={user} handleUserUpdate={handleUserUpdate} key={user.email}/>)}

              </TableBody>
            </Table>
          </TableContainer>
          
        </CardContent>
      </Card>
      <AddUserCard />
    </>
    
  )
}

type AccountInformationProps = {
  account: Account | undefined
}

const AccountInformation = ({account}: AccountInformationProps): JSX.Element => {
  
  const updateAccount = async({}: AccountUpdateValues): Promise<void> => {
    console.log('Need to update the account')
  
  }
  return (
    <Card>
        <div className='card-header'>
          <div className='bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1'>
          <h4 className='text-white font-weight-bold '>
            Account Information
          </h4>
          </div>
        </div>
        <CardContent>
          <Formik initialValues={{
            email: account ? account?.email : '',
            first_name: account ? account.first_name : '',
            last_name: account ? account.last_name : '',

          }}
          onSubmit={updateAccount}
          >
            {(formik) => (
              <Form>
              <div className='row'>
                
                  {/* <p>{ account?.first_name} {account?.last_name}</p>
                  <p>{account?.email}</p> */}
                  <div className='col-12'>
                  <CustomTextField label='Email' name='email' changeHandler={(e) => {formik.setFieldValue('email', e.target.value)}} isMultline={false}/>
                  </div>
                  <div className='col-12'>
                  <CustomTextField label='First Name' name='first_name' changeHandler={(e) => {formik.setFieldValue('first_name', e.target.value)}} isMultline={false}/>
                  </div>
                  <div className='col-12'>
                  <CustomTextField label='Last Name' name='last_name' changeHandler={(e) => {formik.setFieldValue('last_name', e.target.value)}} isMultline={false}/>
                  </div>
                
              </div>
              <Button type={'submit'}>Update Account</Button>

              </Form>

            )}
            

          </Formik>
        

        </CardContent>
    
    <p>Something</p>
  </Card>

  )
}

type AccountUpdateValues = {
  email: string,
}

const AccountPage = ({}): JSX.Element => {
  const { account } = useContext(AccountContext)
  
  console.log(account)
  return (
    <div className='main-content' style={{'height': '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12' style={{
                    position: 'sticky',
                    top: 0,
                }}>
                    <Header />

                </div>
            </div>
            
        {/* <AccountInformation account={account}/> */}
        { account?.is_company_manager ? <CompanyManagerCard /> : <>More to come</>}
        </div>
    
        <Footer />
    </div>
  );
};

export default AccountPage;