import React from 'react';

const Footer = ({}): JSX.Element => {
  return (
   <div className='footer'
    style={{
        position: 'sticky',
        bottom: 0

    }}
   >
    <div className='continer-fluid'>
    <p>&copy; 2024 Dashboard | Developed by <a href='www.aimloperations.com'>AI ML Operations,</a></p>
    </div>
    </div>

  );
};

export default Footer;