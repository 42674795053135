import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { Announcement, AnnouncementType, Conversation, ConversationType } from '../../data';
import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../../axiosApi';
import { JsxEmit } from 'typescript';
import AsyncChat from '../../components/AsyncChat/AsyncChat';
import ConversationLog from '../../components/ConversationLog/ConversationLog';
import { Col, Container, Row } from 'react-bootstrap';
import { Box, Typography } from '@mui/material';
interface ConfirmEntryProps {
    status: string;
    message: string;
}

const AnnouncementBadge = ({status, message}: PropsWithChildren<ConfirmEntryProps>): JSX.Element => {
    return(
        <div className={`alert alert-${status}`}>
            { message }
        </div>
    )
}

const AsyncDashboard = ({}): JSX.Element => {
    const [announcements, setAnnouncement] = useState<Announcement[]>([]);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [selectedConversation, setSelectedConversation] = useState<number | undefined>(undefined);
    const [reconnectAttempts, setReconnectAttempts] = useState<number>(0);
    const maxReconnectAttempts = 5;
    const messageResponse = useRef('');

    async function GetAnnouncements(){
        const response: AxiosResponse<AnnouncementType[]> = await axiosInstance.get('announcment/get/')
        setAnnouncement(response.data.map((status, message) => new Announcement({
            status: status,
            message: message
        })))

    }

    async function GetConversations(){
        const {data, }: AxiosResponse<ConversationType[]> = await axiosInstance.get(`conversations`)
        console.log(data)
        setConversations(data.map((item) => new Conversation({
            id: item.id,
            title: item.title
        })))        
    }

    useEffect(() => {
        GetAnnouncements();
        GetConversations();
    }, [selectedConversation])

    function deleteConversation(conversation_id: number | undefined){
        console.log(`detele ${conversation_id}`)
        try{
            axiosInstance.delete(`conversation_details`, {
                data: {'conversation_id':conversation_id}
            })
            // remove it from the list now
            setConversations(conversations.filter((conversation) => conversation.id !== conversation_id));

            // if it the current selected one, update the selected conversation
            if (selectedConversation === conversation_id){
                setSelectedConversation(undefined)   
            }

            

            

        }catch{

        }
    }

    const conversationTitle = conversations.find(item => item.id === selectedConversation)?.title ?? 'New Conversation';

    return(
        <>
        <Header />
        <Container fluid>
        <Row>
          <Col xs={3} className="sidebar">
            <Typography variant="h6">Conversations</Typography>
            {/* {conversations.map((conv) => (
              <ConversationCard key={conv.id} title={conv.title} />
            ))} */}
            <ConversationLog conversations={conversations} setSelectConversation={setSelectedConversation} deleteConversation={deleteConversation} setConversations={setConversations}/>
          </Col>
          <Col xs={9} className="main-content">
            <Box className="message-box">
              {/* {messages.map((msg, index) => (
                <MessageCard key={index} message={msg} />
              ))} */}
              <AsyncChat selectedConversation={selectedConversation} conversationTitle={conversationTitle} setConversations={setConversations} conversations={conversations} setSelectedConversation={setSelectedConversation}/>
            </Box>
          </Col>
        </Row>
      </Container>
    
        <Footer />
        </>
    )
}

export default AsyncDashboard;