import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

interface ConversationCardProps {
  title: string;
}

const ConversationCard: React.FC<ConversationCardProps> = ({ title }) => {
  return (
    <Card style={{ marginBottom: '10px' }}>
      <CardContent>
        <Typography variant="h6">{title}</Typography>
      </CardContent>
    </Card>
  );
};

export default ConversationCard;