import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

interface MessageCardProps {
  message: string;
}

const MessageCard: React.FC<MessageCardProps> = ({ message }) => {
  return (
    <Card style={{ marginBottom: '10px' }}>
      <CardContent>
        <Typography variant="body1">{message}</Typography>
      </CardContent>
    </Card>
  );
};

export default MessageCard;