import { Form, Formik } from 'formik';
import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from 'react';
import { Alert, Button, TextField } from '@mui/material';
import { object, ref, string } from 'yup';
import { axiosInstance } from '../../../axiosApi';
import CustomTextField, { CustomTextFieldProps } from '../../components/CustomTextField/CustomTextField';
import CustomPasswordField from '../../components/CustomPasswordField/CustomPasswordField';
import CustomToastMessage from '../../components/CustomToastMessage/CustomeToastMessage';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../contexts/AccountContext';
import { MOCK_ACCOUNTS } from '../../mockData';
import { AxiosResponse } from 'axios';
import { Account, AccountType } from '../../data';

import background from '../../../bg.jpeg'



export type SignInValues = {
    email: string;
    password: string;
};

const validationSchema = object().shape({

})

interface GetUserResponse {
    email: string
}

const SignIn = ({}): JSX.Element => {
    const {authenticated, setAuthentication, setNeedsNewPassword} = useContext(AuthContext);
    const { account, setAccount} = useContext(AccountContext);
    const navigate = useNavigate();    
    const [errorMessage, setErrorMessage] = useState<string>('');
    
    const handleSignIn = async ({email, password}: SignInValues): Promise<void> => {
        //const curr_account = MOCK_ACCOUNTS.find((account) => account.email === email)
        //if (curr_account){
        try{
            const response = await axiosInstance.post('/token/obtain/',{
                username: email,
                password: password,

            })
            axiosInstance.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);

            const get_user_response: AxiosResponse<AccountType> = await axiosInstance.get('/user/get/')          

            const account = new Account({
                email: get_user_response.data.email,
                first_name: get_user_response.data.first_name,
                last_name: get_user_response.data.last_name,
                is_company_manager: get_user_response.data.is_company_manager,
                has_signed_tos: get_user_response.data.has_signed_tos,
                company: {
                    id: get_user_response.data.company?.id,
                    name: get_user_response.data.company?.name,
                    state: get_user_response.data.company?.state,
                    zipcode: get_user_response.data.company?.zipcode,
                    address: get_user_response.data.company?.address,
                }
                
            });
            setAccount(account);
            setAuthentication(true)
            setNeedsNewPassword(get_user_response.data.has_usable_password)
            // TODO: terms of service
            if (account.has_signed_tos){
                navigate('/');
            } else {
                navigate('/terms_of_service/')
            }

        }catch(error){ 
            console.log(error)
            setErrorMessage('Error retrieving account. Try again');
        }
    }

    


  return (
    
    <div className='main-content' style={{'height': '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundImage: `url(${background})`,backgroundSize: "cover",
        backgroundRepeat: "no-repeat",}}>
        <div className='container my-auto'>
            <div className='row'>
                <div className='col -lg-4 col-md-8 col-12 mx-auto'>
                    <div className='card z-index-0 fadeIn3 fadeInBottom'>
                        <div className='card-header p-0 position-relative mt-n4 mx-3 z-index-2'>
                            <div className='bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1'>
                                <h4 className='text-white font-weight-bold text-center'>Sign In</h4>
                            </div>
                        </div>
                        <div className='card-body text-center'>
                            <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            onSubmit={handleSignIn}
                            validationSchema={validationSchema}
                            >
                                {(formik) => (
                                    <Form>
                                        {errorMessage &&
                                        <Alert severity="error">{errorMessage}</Alert>

                                        }
                                        
                                        <div className='row'>
                                            <div className='col'>
                                                <CustomTextField 
                                                      label='Email'
                                                      name="email" 
                                                      changeHandler={(e) => formik.setFieldValue('email', e.target.value)} isMultline={false}/>

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <CustomPasswordField 
                                                      label='Password'
                                                      name="password" 
                                                      changeHandler={(e) => formik.setFieldValue('password', e.target.value)}                                                />

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <Button 
                                                type={'submit'}
                                                // disabled={ formik.isSubmitting}
                                                    //   type={'submit'}
                                                    //   loading={formik.isSubmitting}
                                                    //   disabled={
                                                    //     !formik.isValid || !formik.dirty || formik.isSubmitting
                                                    // }
                                                      >
                                                        Sign In
                                                      </Button>
                                                      

                                            </div>
                                        </div>

                                    </Form>
                                )}

                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default SignIn;