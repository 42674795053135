import { ReactNode, useState, createContext } from "react"
import { Account } from "../data";

type AccountProviderProps ={
    children? : ReactNode;
}

type IAccountContext = {
    account: Account | undefined;
    setAccount: (newState: any | undefined) => void;
}

const initialValues = {
    account: undefined,
    setAccount: () => {}
}

const AccountContext = createContext<IAccountContext>(initialValues);

const AccountProvider = ({children}: AccountProviderProps) => {
    const [account, setAccount] = useState(initialValues.account);
    return (
        <AccountContext.Provider value={{account, setAccount}}>
            {children}
        </AccountContext.Provider>
    )
}

export { AccountContext, AccountProvider }