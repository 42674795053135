import { AccountContext } from "./AccountContext"

const { useEffect, createContext, useRef, useState, useContext } = require("react")


const WebSocketContext = createContext()

function WebSocketProvider({ children }) {
    const ws = useRef(null)
    const [socket, setSocket] = useState(null)
    const channels = useRef({}) // maps each channel to the callback
    const { account, setAccount } = useContext(AccountContext)
    const [currentChannel, setCurrentChannel] = useState('')
    /* called from a component that registers a callback for a channel */
    const subscribe = (channel, callback) => {
        console.log(`Subbing to ${channel}`)
        setCurrentChannel(channel)
        channels.current[channel] = callback
    }
    /* remove callback  */
    const unsubscribe = (channel) => {
        delete channels.current[channel]
    }

    const sendMessage = (message, conversation_id) => {
        if (socket && socket.readyState === WebSocket.OPEN){
            
            socket.send(`${conversation_id} | ${message}`)
        }else{
            console.log('WebSocket is not open')
        }

    }
    useEffect(() => {
        /* WS initialization and cleanup */
        console.log(`ws account: ${account?.email}`)
        // if(account?.email){
        //     ws.current = new WebSocket(`ws://127.0.0.1:8001/ws/chat/${account?.email}/`)

        // }else{
        // ws.current = new WebSocket(`ws://127.0.0.1:8001/ws/chat/`)
        // ws.current = new WebSocket('wss://chatbackend.aimloperations.com/ws/chat/')
        
        //ws.current = new WebSocket(`ws://127.0.0.1:8001/ws/chat_again/`);
        ws.current = new WebSocket('wss://chatbackend.aimloperations.com/ws/chat_again/')
        setSocket(ws.current);
        
        


        ws.current.onopen = () => { console.log('WS open') }
        ws.current.onclose = () => { console.log('WS close') }
        ws.current.onmessage = (message) => {
            const data = message.data
            // lookup for an existing chat in which this message belongs
            // if no chat is subscribed send message to generic channel
            const chatChannel = Object.entries(channels.current)[0][0]

            if (channels.current[chatChannel]) {
                /* in chat component the subscribed channel is `MESSAGE_CREATE_${id}` */
                channels.current[chatChannel](data)
            } else {
                /* in notifications wrapper the subscribed channel is `MESSAGE_CREATE` */
                console.log('Error')
                // channels.current[type]?.(data)
            }
        }
        return () => { ws.current.close() }
    }, [])

    

    /* WS provider dom */
    /* subscribe and unsubscribe are the only required prop for the context */
    return (
        <WebSocketContext.Provider value={[subscribe, unsubscribe, socket, sendMessage]}>
            {children}
        </WebSocketContext.Provider>
    )
}

export { WebSocketContext, WebSocketProvider }