import React, { ReactNode } from 'react';

export type CardProps = {
    children?: ReactNode
};

const Card = ({children}: CardProps): JSX.Element => {
    return(
        <div className='card card-body'>
            {children}
        </div>
        
    )
}

export default Card;